import {defaultPagination, gridPagination} from "@/services/pagination";
import {
  createSeedingTemplate,
  editSeedingTemplate, favorites,
  getTemplateById,
  listTemplates
} from "@/services/api/app/salesforce/recovery/seeding/seeding"
import {getTemplateTypes} from "@/services/enums";

const defaultState = () => ({
  templates: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
  },
  gridPagination: {
    ...gridPagination(),
    sortBy: 'name',
  },
  search: {
    searchTerm: ""
  },
  isFavorite: null,
})

export default {
  namespaced: true,

  actions: {
    listTemplates({ commit, state }, data) {
      return listTemplates({
        ...data,
        pagination: state.pagination,
        searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm,
        filter: null
      })
        .then(({ result, pagination }) => {
          commit('setTemplates', result);
          commit('setPagination', pagination);
        })
        .catch(error => {
          console.error("Failed to list templates:", error);
          throw error;
        });
    },

    listTemplatesGridView({ commit, state }, data) {
      console.log("grid pagination ", data.pagination)
      return listTemplates({
        ...data,
        pagination: data.pagination,
        searchTerm: data.searchTerm || state.search.searchTerm,
        filter: null,
      })
        .then(({ result, pagination }) => {
          const updatedTemplates = result.map((template) => ({
            ...template
          }));

          if (data.pagination?.pageNumber > 1) {
            commit("appendTemplates", updatedTemplates);
          } else {
            commit("setTemplates", updatedTemplates);
          }

          commit("setGridPagination", pagination);
        })
        .catch((error) => {
          console.error("Failed to list templates:", error);
          throw error;
        });
    },

    createSeedingTemplate({commit, dispatch, state}, data) {
      return createSeedingTemplate({
        ...data
      })
        .then(() => {
          return dispatch('listTemplates', {
            searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm,
            pagination: state.pagination,
            filter: null
          });
        })
        .catch((error) => {
          console.error("Failed to create template:", error);
          throw error;
        });
    },

    editTemplate({ dispatch, state }, data) {
      return editSeedingTemplate({
        ...data,
      })
        .then(() => {
          return dispatch("listTemplates", {
            searchTerm: state.search.searchTerm,
            pagination: state.pagination,
            filter: null,
          });
        })
        .catch((error) => {
          console.error("Failed to edit template:", error);
          throw error;
        });
    },

    getTemplateById({ commit }, id) {
      return getTemplateById({ id })
        .then((template) => {
          commit("setTemplateDetails", template);
          return template;
        })
        .catch((error) => {
          console.error("Failed to fetch template by ID:", error);
          throw error;
        });
    },

    getFavorites({ commit }, id) {
      return favorites({ id })
        .then((favorites) => {
          commit("setFavorites", favorites);
          return favorites;
        })
        .catch((error) => {
          console.error("Failed to add to favorites:", error);
          throw error;
        });
    }
  },

  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setTemplateDetails(state, templateDetails) {
      state.templateDetails = templateDetails;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setGridPagination(state, pagination) {
      state.gridPagination = pagination;
    },
    setSearch(state, search) {
      state.search = search
    },
    setFavorites(state, { id, isFavorite }) {
      const template = state.templates.find((t) => t.id === id);
      if (template) {
        template.isFavorite = isFavorite;
      }
    },
    resetPagination(state, newPagination = defaultPagination()) {
      state.pagination = newPagination;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
    appendTemplates(state, templates) {
      state.templates = [...state.templates, ...templates];
    },
  },
  getters: {
    getAllTemplates(state) {
      return state.templates.map(template => ({
        ...template,
        templateTypeLabel: getTemplateTypes(template.templateType)
      }));
    },
  },

  state: defaultState()
}
