<template>
  <div class="i-date-time">
    <div v-if="placeholder" class="field-label">
      {{ placeholder }}
    </div>

    <img src="../../../assets/app/icons/calender-icon.svg" class="calendar-icon">
    <div @click.stop="onDateClick">
      <VueCtkDateTimePicker
        ref="Datetimepicker"
        no-label
        :label="dateValue"
        :no-button-now="noButtonNow"
        :noClearButton="true"
        :value="dateValue"
        :no-header="true"
        :locale="'en'"
        @input="onInput">
      </VueCtkDateTimePicker>
    </div>

    <div
      v-if="showCurtain"
      ref="Curtain"
      class="date-time-curtain"
      @click="onCurtainDiv"
    ></div>
  </div>
</template>

<script>

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import {formatISO} from 'date-fns'

export default {
  name: "IDateTime",
  components: {VueCtkDateTimePicker},
  props: {
    value: {
      type: [Date, String, Number],
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    maxDate: {
      type: [Date, String, Number],
    },
    minDate: {
      type: [Date, String, Number],
    },
    noButtonNow: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mask: 'MM.DD.YYYY, hh:mm:ss',
      showCurtain: false,
    }
  },

  computed: {
    dateValue() {
      const date = new Date(this.value);
      return formatISO(date);
    },
    minDateValue() {
      // return date.formatDate(this.minDate, 'YYYY-MM-DD HH:mm');
      return this.minDate
    },
    maxDateValue() {
      // return date.formatDate(this.maxDate, 'YYYY-MM-DD HH:mm');
      return this.maxDate
    },
  },

  methods: {
    onInput(value) {
      const result = formatISO(new Date(value))
      this.$emit('input', result);
    },
    onDateClick() {
      if (this.$refs.Datetimepicker.pickerOpen) {
        this.showCurtain = true;
      }
    },
    onCurtainDiv() {
      this.showCurtain = false;
    },

    hideCurtainDiv() {
      if (this.$refs.Datetimepicker.pickerOpen && this.showCurtain) {
        this.showCurtain = false;
        this.$refs.Datetimepicker.pickerOpen = !this.$refs.Datetimepicker.pickerOpen
      }
    },
  },


  created() {
    if (this.dateValue) {
      this.onInput(this.dateValue);
    }
    document.addEventListener('click', this.hideCurtainDiv)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.hideCurtainDiv)
  }

}
</script>

<style lang="scss">
@import "IDateTime";
</style>
