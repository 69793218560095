import {defaultPagination} from "@/services/pagination";
import {
  listCompare,
  deleteCompare,
  downloadCompare,
  cancelCompare,
  listComparison
} from "@/services/api/app/salesforce/recovery/compare/compare";

const defaultState = () => ({
  compares: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'startedAt'
  },

  comparison: [],
  paginationComparison: {
    ...defaultPagination(),
    sortBy: 'startedAt'
  },
  comparisonDetails: [],
});

export default {
  namespaced: true,
  actions: {
    listCompare({commit, state}) {
      return listCompare({
        pagination: state.pagination,
        sourceType: 'SALESFORCE',
        dataTypes: ['SALESFORCE'],
      })
        .then(({result, pagination}) => {
          commit('setCompares', result)
          commit('setPagination', pagination)
        })
    },
    deleteCompare({commit, state}, {id}) {
      return deleteCompare(id)
    },
    downloadCompare({commit, state}, {id}) {
      return downloadCompare(id)
    },
    cancelCompare({commit, state}, {id}) {
      return cancelCompare(id, 'salesforce')
    },
    listComparison({commit, state}, data) {
      return listComparison({
        pagination: state.paginationComparison,
        id: data.id,
        searchTerm: data.searchTerm
      })
        .then(({result, pagination}) => {
          commit('setComparison', result?.result)
          commit('setPaginationComparison', pagination)
          commit('setComparisonDetails', result)
        })
    },
  },

  mutations: {
    setCompares(state, compares) {
      state.compares = compares;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'startedAt',
      };
    },
    setComparison(state, comparison) {
      state.comparison = comparison;
    },
    setPaginationComparison(state, paginationComparison) {
      state.paginationComparison = paginationComparison;
    },
    setComparisonDetails(state, comparison) {
      state.comparisonDetails = comparison;
    },
    resetPaginationComparison(state) {
      state.paginationComparisonDetails = {
        ...defaultPagination(),
        sortBy: 'startedAt',
      };
    },

    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  state: defaultState(),
}
