import {GET, POST} from "@/services/gateway";

export const createSeedingTemplate = ({name, description, templateType, isFavorite = false}) => {
  return POST('seeding/create-template', {name, description, templateType, isFavorite})
}

export const editSeedingTemplate = ({id, name, description, templateType, isFavorite = false}) => {
  return POST('seeding/edit-template', {id, name, description, templateType, isFavorite})
}

export const listTemplates = ({pagination, searchTerm, templateType}) => {
  return POST('seeding/list-templates', {pagination, searchTerm, templateType})
}

export const getTemplateById = ({id}) => {
  return GET(`seeding/template`, {id})
}

export const favorites = ({id}) => {
  return GET('seeding/favorites', {id})
}
