<template>
  <div class="i-date-time">
    <div class="field-label">
      {{ placeholder }}
    </div>

    <img src="../../../assets/app/icons/calender-icon.svg" class="calendar-icon">
    <div @click.stop="onDateClick">
      <VueCtkDateTimePicker
        ref="Datetimepicker"
        formatted="l"
        no-label
        only-date
        :label="dateValue"
        :no-button-now="noButtonNow"
        :noClearButton="true"
        :value="selectedDate"
        :no-header="true"
        :only-date="true"
        :max-date="maxDate"
        :locale="'en'"
        @input="onInput"
        @validate="onValidate"
      />
    </div>

    <div
      v-if="showCurtain"
      ref="Curtain"
      class="date-time-curtain"
      @click="onCurtainDiv"
    ></div>
  </div>
</template>

<script>

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import {addMinutes, endOfDay, startOfDay} from 'date-fns'

export default {
  name: "IDateTime",
  components: {VueCtkDateTimePicker},
  props: {
    value: {
      type: [Date, String, Number],
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    isAllDay: {
      type: Boolean,
      default: false,
    },
    noButtonNow: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    customMaxDate: {
      type: String
    },
    customSelectedDate: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isLoading: false,
      selectedDate: null,
      showCurtain: false,
    }
  },

  computed: {
    maxDate() {
      return this.customMaxDate ? this.customMaxDate : new Date().toISOString()
    },
    dateValue() {
      let dateFormatter = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale,)
      return dateFormatter.format(new Date(this.value));
    },
  },

  methods: {
    onInput(val) {
      val = val.replace(/-/g, '/')
      const isoDate = new Date(val)
      let dateFrom = startOfDay(isoDate)

      this.minDate = dateFrom

      if (this.isAllDay) {
        dateFrom = endOfDay(isoDate)
      }

      const finalDate = addMinutes(dateFrom, isoDate.getTimezoneOffset() * (-1))

      if (this.customSelectedDate) {
        this.selectedDate = isoDate.toISOString();
      } else {
        this.selectedDate = new Date(finalDate).toISOString();
        this.$emit('input', dateFrom.toISOString());
      }
    },
    onValidate() {
      this.$refs.Datetimepicker.pickerOpen = !this.$refs.Datetimepicker.pickerOpen;
      this.showCurtain = false;
      this.$emit('input', this.selectedDate);
    },
    onDateClick() {
      if (this.$refs.Datetimepicker.pickerOpen) {
        this.showCurtain = true;
      }
    },
    onCurtainDiv() {
      this.showCurtain = false;
    },

    hideCurtainDiv() {
      if (this.$refs.Datetimepicker.pickerOpen && this.showCurtain) {
        this.showCurtain = false;
        this.$refs.Datetimepicker.pickerOpen = !this.$refs.Datetimepicker.pickerOpen
      }
    }
  },

  created() {
    this.selectedDate = this.customSelectedDate;
    document.addEventListener('click', this.hideCurtainDiv)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.hideCurtainDiv)
  }
}
</script>

<style lang="scss">
@import "IDateTime";
</style>
